import {
  SourceTypes,
  SourceTypesLabels,
  WebSearchTypes,
  WebSearchTypesLabels,
} from 'src/types';
import { Image, Video } from '@phosphor-icons/react';
import { ResearchWebSearchEngine } from 'src/types/models/ResearchWebSearchEngine';
import { DeepResearchOpenAiModel } from 'src/types/models/DeepResearchOpenAiModel';
import { DeepResearchGoogleModel } from 'src/types/models/DeepResearchGoogleModel';
import { ResearchLlmOpenAiModel } from 'src/types/models/ResearchLlmOpenAiModel';
import { ResearchLlmGoogleModel } from 'src/types/models/ResearchLlmGoogleModel';
import { ResearchLlmAnthropicModel } from 'src/types/models/ResearchLlmAnthropicModel';
import { SelectOption } from 'src/components/Select';

import { CodeSourceSettingsTypes, CodeSourceTypesLabels } from 'src/types';

export type CodeSourceItemType = {
  value: CodeSourceSettingsTypes;
  label: CodeSourceTypesLabels;
  icon: string;
  name: CodeSourceSettingsTypes;
  options: SelectOption[];
};

export const webSearchTypesList = [
  {
    value: WebSearchTypes.IMAGE_SEARCH,
    label: WebSearchTypesLabels.IMAGE_SEARCH,
    icon: Image,
  },
  {
    value: WebSearchTypes.VIDEO_SEARCH,
    label: WebSearchTypesLabels.VIDEO_SEARCH,
    icon: Video,
  },
];

export const webSearchOptions = [
  {
    label: 'Google or Bing',
    value: ResearchWebSearchEngine.FASTEST,
  },
  {
    label: 'Google',
    value: ResearchWebSearchEngine.GOOGLE,
  },
  {
    label: 'Bing',
    value: ResearchWebSearchEngine.BING,
  },
];

export const deepResearchOptions = [
  {
    label: 'GPT-4',
    value: DeepResearchOpenAiModel.GPT_4,
  },
  {
    label: 'GPT-4 Turbo',
    value: DeepResearchOpenAiModel.GPT_4_1106_PREVIEW,
  },
  {
    label: 'Gemini 1.0 Pro',
    value: DeepResearchGoogleModel.GEMINI_1_0_PRO,
  },
];

export const openAIOptions = [
  {
    label: 'GPT-3.5 Turbo',
    value: ResearchLlmOpenAiModel.GPT_3_5_TURBO_1106,
  },
  {
    label: 'GPT-4 Turbo',
    value: ResearchLlmOpenAiModel.GPT_4_1106_PREVIEW,
  },
];

export const googleGeminiOptions = [
  {
    label: 'Gemini 1.0 Pro',
    value: ResearchLlmGoogleModel.GEMINI_1_0_PRO,
  },
];

export const anthropicOptions = [
  {
    label: 'Claude 3 Sonnet',
    value: ResearchLlmAnthropicModel.CLAUDE_3_SONNET_20240229,
  },
  {
    label: 'Claude 3 Opus',
    value: ResearchLlmAnthropicModel.CLAUDE_3_OPUS_20240229,
  },
];

export type LLMSourceItemType = {
  value: SourceTypes;
  label: SourceTypesLabels;
  icon: string;
  name: SourceTypes;
  options: SelectOption[];
};

export const sourceTypeList = [
  {
    value: SourceTypes.OPEN_AI_ENABLED,
    label: SourceTypesLabels.OPEN_AI,
    icon: 'chatGPTLogo',
    options: openAIOptions,
    name: SourceTypes.OPEN_AI,
  },
  {
    value: SourceTypes.GOOGLE_ENABLED,
    label: SourceTypesLabels.GOOGLE,
    icon: 'googleGeminiProLogo',
    options: googleGeminiOptions,
    name: SourceTypes.GOOGLE,
  },
  {
    value: SourceTypes.ANTHROPIC_ENABLED,
    label: SourceTypesLabels.ANTHROPIC,
    icon: 'claudeLogo',
    options: anthropicOptions,
    name: SourceTypes.ANTHROPIC,
  },
];

export enum ExternalModelProvider {
  AMAZON_BEDROCK = 'amazon_bedrock',
  GOOGLE = 'google',
  OPENAI = 'openai',
  TOGETHER_AI = 'together_ai',
}

export enum ImageGeneratorsModelProvider {
  BEDROCK = 'bedrock',
  OPENAI = 'openai',
  NINJA_PIX = 'sagemaker',
  NINJA_PIX_PRO = 'anywhere',
}

export const externalModelProvidersSet = {
  [ExternalModelProvider.AMAZON_BEDROCK]: {
    icon: 'awsLogo',
    label: 'Amazon Bedrock',
    url: 'https://aws.amazon.com/bedrock/?refid=0eaabb80-ee46-4e73-94ae-368ffb759b62',
  },
  [ExternalModelProvider.OPENAI]: {
    icon: 'chatGPTLogo',
    label: 'OpenAI',
    url: 'https://platform.openai.com/docs/models',
  },
  [ExternalModelProvider.GOOGLE]: {
    icon: 'googleGeminiProLogo',
    label: 'Google',
    url: 'https://ai.google.dev/gemini-api/docs/models/gemini',
  },
};

// the list of external models which will have "NEW" label
export const newExternalModelsList = [
  'bedrock/amazon/amazon.titan-text-premier-v1:0',
  'bedrock/mistral/mistral.mistral-large-2402-v1:0',
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20241022-v2:0',
  'gemini/google/gemini-1.5-pro',
  'gemini/google/gemini-1.5-flash',
  'openai/openai/gpt-4o',
];

// the list of image generator models which will have "NEW" label
export const newImageGeneratorModels: string[] = [];

export enum ImageGenerationModels {
  PIX = 'alias/ninjatech/ninja-pix', // pix art
  PIX_PRO = 'alias/ninjatech/ninja-pix-pro', // flux
  FLUX_PRO = 'togetherai/blackforestlabs/flux1.1.pro',
  FLUX_SCHNELL = 'togetherai/blackforestlabs/flux.schnell',
}

export const externalModelsSet: Record<
  string,
  {
    icon_name: string;
    display_name: string;
    provider?: ExternalModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  'bedrock/amazon/amazon.titan-text-express-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Express',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/us.amazon.nova-micro-v1:0': {
    icon_name: 'nova',
    display_name: 'Amazon Nova Micro',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/us.amazon.nova-lite-v1:0': {
    icon_name: 'nova',
    display_name: 'Amazon Nova Lite',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/us.amazon.nova-pro-v1:0': {
    icon_name: 'nova',
    display_name: 'Amazon Nova Pro',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-text-lite-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Lite',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-text-premier-v1:0': {
    icon_name: 'awsLogo',
    display_name: 'Amazon Titan Text Premier',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-haiku-20240307-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-5-haiku-20241022-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-opus-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Opus',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20240620-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-mid-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Mid',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-ultra-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Ultra',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-sonnet-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20241022-v2:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-plus-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R+',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-small-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Small',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-large-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Large',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // @deprecated
  'gemini/google/gemini-1.0-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.0 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Flash',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4-0125-preview': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4o-2024-05-13': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-3.5-turbo-1106': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-3.5 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o-mini': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o Mini',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'Ninja SuperAgent',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:turbo': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Turbo',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:nexus': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Nexus',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:apex': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Apex',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'togetherai/deepseek/deepseek-v3': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek V3',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
  'anywhere/deepseek/deepseek-v3': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek V3',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
  'anywhere/deepseek/deepseek-r1': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek R1',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
};

export const imageGeneratorsModelsSet: Record<
  string,
  {
    display_name: string;
    icon_name?: string;
    provider: ImageGeneratorsModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  'openai/openai/dall-e-3': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 3',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/dall-e-2': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 2',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'bedrock/amazon/amazon.titan-image-generator-v1': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-image-generator-v2:0': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.nova-canvas-v1:0': {
    display_name: 'Amazon Nova Canvas',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'nova',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/stability.stable-diffusion-xl-v1': {
    display_name: 'Stable Diffusion XL 1.0',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'bedrockStableLogo',
  },
  [ImageGenerationModels.PIX]: {
    display_name: 'Ninja PixArt',
    provider: ImageGeneratorsModelProvider.NINJA_PIX,
    icon_name: 'ninjaOutlined',
  },
  [ImageGenerationModels.PIX_PRO]: {
    display_name: 'Ninja Flux',
    provider: ImageGeneratorsModelProvider.NINJA_PIX_PRO,
    icon_name: 'ninjaPixProLogo',
  },
  [ImageGenerationModels.FLUX_PRO]: {
    display_name: 'Ninja Flux',
    provider: ImageGeneratorsModelProvider.NINJA_PIX_PRO,
    icon_name: 'ninjaPixProLogo',
  },
  [ImageGenerationModels.FLUX_SCHNELL]: {
    display_name: 'Ninja Flux',
    provider: ImageGeneratorsModelProvider.NINJA_PIX_PRO,
    icon_name: 'ninjaPixProLogo',
  },
};

export const SUPER_AGENT_AS_EXTERNAL_MODEL = 'alias/ninjatech/super-agent';
export const NINJA_AGENT_TITLE = 'Ninja Agent';
