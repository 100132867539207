import {
  ChitChatCard,
  MessageTabData,
  MessageTabs,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { MessageContent } from '../MessageContent';
import { useMemo } from 'react';
import { prepareExternalModelsTabContent } from 'src/utils';
import { TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { renderMessageTabList } from '../../renderMessageTabList';
import { NINJA_AGENT_TITLE } from 'src/constants';

interface ChatCardProps {
  message: SharedMessageType;
}

export const ChatCard = ({ message }: ChatCardProps) => {
  const { data } = message.payload as ChitChatCard;

  const { referenced_model_summary } = data || {};
  const { references } = referenced_model_summary || {};
  const ninja_model_output = data.ninja_model_output || '';

  const tabContent = useMemo(
    () => (references ? prepareExternalModelsTabContent(references) : []),
    [references],
  );

  const tabsData: MessageTabData[] = useMemo(() => {
    const filteredTabContent = tabContent.filter(
      ({ tabName }) => tabName !== MessageTabs.DEEP_RESEARCH,
    );

    return [
      ...(ninja_model_output
        ? [
            {
              tabName: MessageTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: NINJA_AGENT_TITLE,
              content: ninja_model_output || '',
            },
          ]
        : []),
      ...filteredTabContent,
    ];
  }, [ninja_model_output, tabContent]);

  return (
    <Tabs<string> defaultActiveTab={MessageTabs.SEARCH_RESULT}>
      {renderMessageTabList({ data: tabsData })}

      {tabsData.map(({ tabName, content, references }) => (
        <TabPanel key={tabName} tab={tabName}>
          <MessageContent
            content={content}
            references={references}
            messageSkill={data.skill}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};
