import classNames from 'classnames';
import { Markdown } from 'src/components/Markdown';
import { Citation } from 'src/types';

interface MessageContentProps {
  content: string;
  className?: string;
  references?: Citation[];
  messageSkill?: string;
}

export const MessageContent = ({
  content,
  className,
  references,
  messageSkill,
}: MessageContentProps) => {
  if (!content) {
    return null;
  }

  return (
    <div className={classNames('nj-thread-message--content', className)}>
      <Markdown references={references} messageSkill={messageSkill}>
        {content}
      </Markdown>
    </div>
  );
};
